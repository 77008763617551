import React from 'react';
import riverside from '../../assets/riverside.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text-sub">Do you want to step in to the future before others?</h1>
    </div>

    <div className="gpt3__footer-btn">
      <button className="gpt3__footer-btn" type="button" style={{background: 'none', border: 'none', color: 'aliceblue'}}>
      <a href='mailto:dave@riverside.co.za'>
      Get in contact first
      </a>
      </button>
    </div>

    {/* <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={riverside} alt="gpt3_logo" />
        <p>Crechterwoord K12 182 DK Alknjkcb, <br /> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Overons</p>
        <p>Social Media</p>
        <p>Counters</p>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>Crechterwoord K12 182 DK Alknjkcb</p>
        <p>085-132567</p>
        <p>info@payme.net</p>
      </div>
    </div> */}

    <div className="gpt3__footer-links">
    <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>dave@riverside.co.za</p>
      </div>
      </div>

    <div className="gpt3__footer-copyright">
      <p>@2022 Riverside. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;