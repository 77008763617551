import React from 'react';
import { AnimateOnChange, animations } from 'react-animation';
import riverside from '../../assets/riverside.png';
import 'react-animation/dist/keyframes.css'
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Bringing you Renewable Energy and Load Shedding solution&apos;s soon.</h1> 
      
      <div className='list'>
        <ul className='list__ul'>  
          <li className='list__item' id='candle' style={{animation: animations.fadeOut, animationDuration: '4500ms'}}>
            <h1>🕯️</h1>
          </li>
          <li className='list__item' id='torch' style={{animation: animations.fadeOut, animationDuration: '4500ms'}}>
            <h1>🔦</h1>
          </li>  
          <li className='list__item wave' id='wave' style={{animation: animations.bounceIn, animationDuration: '3500ms'}}>
            <h1>👋</h1>
          </li>     
          <li className='list__item wave' id='light' style={{animation: animations.bounceIn, animationDuration: '3500ms'}}>
            <h1>💡</h1>
          </li> 
        </ul>
      </div>
      
      
      <div className="gpt3__header-image">
      <img src={riverside} alt='Riverside Logo' />
    </div>
      
      {/* <p>Bringing your renewable energy and load shedding solution&apos;s soon.</p> */}

      {/* <div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Get Started</button> 
      </div> */}

      {/* <div className="gpt3__header-content__people">
        <img src={people} />
        <p>1,600 people requested access a visit in last 24 hours</p>
      </div> */}
    </div>

  
  </div>
);

export default Header;